// import Vue from 'vue'
// import App from './Index.vue'
// import router from './router'
// import store from './store'
// import './plugins/element.js'
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
//
// Vue.config.productionTip = false
//
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'es6-promise/auto'

// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts

import * as echarts from "echarts";
import 'echarts-wordcloud';
import wordcloud from 'vue-wordcloud'
Vue.prototype.$echarts = echarts
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
import VueQriously from 'vue-qriously'
// import 'lib-flexible'
Vue.use(VueQriously)
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
import store from './store/index'
Vue.config.productionTip = false
Vue.use(ElementUI);
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax)

import './assets/js/emjoymethod'
import './assets/css/emjoy.css'

import './assets/css/iconfont.css'

// import './assets/data/world.js'
import './assets/data/world.js' // 引入世界地图
import VWave from 'v-wave'
Vue.use(VWave)
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

// import SummernoteEditor

import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)

import "overlayscrollbars/css/OverlayScrollbars.css";
import OverlayScrollbars from "overlayscrollbars";

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);


import VueContextMenu from 'vue-contextmenu'
Vue.use(VueContextMenu)

Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": false, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
});
// import MAlert from '@/components/Malert/index'
// Vue.use(MAlert)
import VueI18n from 'vue-i18n'
Vue.use(VueI18n) ;

import vueJsonExcelUmd from "vue-json-excel";
Vue.component("downloadExcel", vueJsonExcelUmd);

import KProgress from 'k-progress';
Vue.component('k-progress', KProgress);

import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementLocal from 'element-ui/lib/locale'


import Video from 'video.js'
import 'video.js/dist/video-js.css'

const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: {
    'zh-CN': {
      ...require('./common/lang/zh'),
      ...require('element-ui/lib/locale/lang/zh-CN')
    },   // 中文语言包
    'en-US': {
      ...require('./common/lang/en'),
      ...require('element-ui/lib/locale/lang/en')
    }    // 英文语言包
  }
})
elementLocal.i18n((key, value) => i18n.t(key, value))

Vue.directive("scrollBar", {
  inserted(el, binding, vnode) {
    el.ins = OverlayScrollbars(el, {
      autoUpdate: true,
      nativeScrollbarsOverlaid: {
        initialize: false
      },
      overflowBehavior: {
        x: 'hidden'
      },
      scrollbars: {
        autoHide: "leave",
        autoHideDelay: 100
      }
    })
  },
  componentUpdated(el, binding, vnode, oldVnode) {
    el.ins.update()
  },
  unbind(el) {
    window.setTimeout(_ => {
      el.ins.destroy()
    }, 100)
  }
})

/**
 * 字符串格式化
 * @param formatted
 * @param args
 * @returns {*}
 */
Vue.prototype.$stringFormat = function stringFormat (formatted, args) {
  for (let i = 0; i < args.length; i++) {
    let regexp = new RegExp('\\{' + i + '\\}', 'gi')
    formatted = formatted.replace(regexp, args[i])
  }
  return formatted
}
import $ from "jquery";

$.ajaxSetup({
  cache: true
});
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,  // 最后
  components: { App },
  template: '<App/>'
})

